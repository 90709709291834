import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from "recoil";
import { Button, Box, Typography, Tooltip, IconButton } from "@mui/material";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import MapProvider from "../components/context/MapProvider";
import Map from "../components/map/Map";
import { useMarkerTools } from "../hooks/useMarkerTools";
import { allSensorsAtom, subscribeTopicAtom, eventSourceAtom, receivedMessagesAtom, filteredMessagesAtom, hoverEventAtom } from "../recoil/atom";
import { convertUnixTimestamp } from "../functions/timestampFunctions";
import { isTokenExpired } from "../functions/authFunctions";
import { redirectToLogin, redirectToConnectionPage } from "../functions/connectionFunctions";
import { baseURL } from "../config"

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

const AlertsPage = () => {
    const navigate = useNavigate();
    const { deleteAllMarkers } = useMarkerTools();
    const [subscribeTopic, setSubscribeTopic] = useRecoilState(subscribeTopicAtom);
    const [allSensors, setAllSensors] = useRecoilState(allSensorsAtom);
    const [eventSource, setEventSource] = useRecoilState(eventSourceAtom);
    const [receivedMessages, setReceivedMessages] = useRecoilState(receivedMessagesAtom);
    const [filteredMessages, setFilteredMessages] = useRecoilState(filteredMessagesAtom);
    const [hoverEvent, setHoverEvent] = useRecoilState(hoverEventAtom);

    const [messageHoverStates, setMessageHoverStates] = useState([]);

    const user = localStorage.getItem("user");
    const role = user ? JSON.parse(user).role : null;

    const [filterOpened, setFilterOpened] = useState(false);
    const mainClassifications = [
        "ADK",
        "Airplane",
        "Blacklisted",
        "Bus",
        "Drone",
        "Micro Mobility",
        "Motorbike",
        "Portable Device",
        "Stationary Device",
        "Train",
        "Truck",
        "Vehicle",
        "Local MAC",
        "Unclassified"
    ]
    const [selectedMains, setSelectedMains] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem("token");
        console.log("isTokenExpired:", isTokenExpired(token))
        if (isTokenExpired(token)) {
            console.log("redirect to login page");
            redirectToLogin(eventSource, setSubscribeTopic, setReceivedMessages);
            navigate("/login");
        };
        if (!isTokenExpired(token) && subscribeTopic === '') {
            if (role === "admin") {
                console.log("redirect to connection page");
                redirectToConnectionPage(eventSource, deleteAllMarkers, setAllSensors, setReceivedMessages, setSubscribeTopic);
                navigate("/connection");
            } else {
                console.log("redirect to login page");
                redirectToLogin(eventSource, setSubscribeTopic, setReceivedMessages);
                navigate("/login");
            };
        };
    }, []);

    useEffect(() => {
        const handleWindowClose = () => {
            eventSource.close()
            navigator.sendBeacon(`${baseURL}/close_sse_connection`);
        };

        window.addEventListener("beforeunload", handleWindowClose);

        return () => {
            window.removeEventListener("beforeunload", handleWindowClose);
        };
    }, []);

    useEffect(() => {
        if (selectedMains.length > 0) {
            const filtered = receivedMessages.filter((message) => !selectedMains.includes(message.payload.main));
            setFilteredMessages(filtered);
        } else {
            setFilteredMessages(receivedMessages);
        }
    }, [receivedMessages, selectedMains]);

    const handleOpenFilter = () => setFilterOpened(true);

    const handleCloseFilter = () => setFilterOpened(false);

    const handleCheckboxChange = (main) => {
        setSelectedMains((prev) => {
            if (prev.includes(main)) {
                return prev.filter((item) => item !== main);
            }
            return [...prev, main];
        });
    };

    const handleSave = () => {
        setFilterOpened(false);
    };

    const handleMessageBoxHover = (index, messagePayload) => {
        setMessageHoverStates(prevStates => {
            const newState = [...prevStates];
            newState[index] = true;
            return newState;
        });
        setHoverEvent(messagePayload);
    };

    const handleMessageBoxLeave = (index) => {
        setMessageHoverStates(prevStates => {
            const newState = [...prevStates];
            newState[index] = false;
            return newState;
        });
        setHoverEvent(null); // Reset the hovered object_id
    };

    const handleDeleteButtonClick = (message) => {
        setFilteredMessages(prevMessages => {
            const updatedMessages = prevMessages.filter(singleMessage => singleMessage.payload.object_id !== message.payload.object_id);
            return updatedMessages;
        });
    };

    const handleCloseEvent = (message) => {
        console.log(message.payload)
        if (message && message.payload) {
            setFilteredMessages(prevMessages => {
                const messageIndex = prevMessages.findIndex(singleMessage => singleMessage.payload.object_id === message.payload.object_id);
                console.log(messageIndex)
                // create a copy of the message and update the status to closed
                const updatedMessage = {
                    ...prevMessages[messageIndex],
                    payload: {
                        ...prevMessages[messageIndex].payload,
                        status: "closed"  // Update the status to "closed"
                    }
                };
                // create new filteredMessages array
                const updatedMessages = [...prevMessages];
                //replace only message with updated on
                updatedMessages[messageIndex] = updatedMessage;

                return updatedMessages;
            });
        };
    };

    const handleDisconnectButton = ()  => {
        redirectToConnectionPage(eventSource, deleteAllMarkers, setAllSensors, setReceivedMessages, setFilteredMessages, setSubscribeTopic, navigate);
        navigate("/connection");
    };

    console.log("receivedMessages:", receivedMessages);
    console.log("filteredMessages", filteredMessages)
    console.log("selectedMains", selectedMains)

    return (
        <MapProvider>
            <div>
                <Box sx={{ display: "flex" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", width: "55%", maxHeight: "80vh", overflowY: "auto" }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography sx={{ fontWeight: "bold", fontSize: "20px", marginTop: "10px", marginBottom: "20px", flexGrow: 1, textAlign: "center" }}>Recent Alerts</Typography>
                           {/* IconButton to open the dialog */}
                           <IconButton
                                className="FilterAllMessages"
                                variant="contained"
                                onClick={handleOpenFilter}
                            >
                                <FilterListIcon sx={{ fontSize: "large", marginRight: "5px" }} />
                            </IconButton>
                            <Dialog open={filterOpened} onClose={handleCloseFilter}>
                                <DialogTitle>Filter Messages</DialogTitle>
                                <DialogContent dividers>
                                    {mainClassifications.map((main, index) => (
                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    checked={selectedMains.includes(main)}
                                                    onChange={() => handleCheckboxChange(main)}
                                                />
                                            }
                                            label={main}
                                        />
                                    ))}
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleCloseFilter}>Cancel</Button>
                                    <Button onClick={handleSave}>Save</Button>
                                </DialogActions>
                            </Dialog>
                           {filteredMessages.length > 0 && (
                                <>
                                    <Tooltip title="Clear Messages">
                                        <IconButton
                                            className="clearAllMessages"
                                            variant="contained"
                                            onClick={() => setReceivedMessages([])}
                                        >
                                            <NotInterestedIcon
                                                sx={{ fontSize: 'large', marginRight: "5px" }}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )}
                        </Box>
                        {filteredMessages && filteredMessages.map((message, index) => (
                            <Box
                                key={index}
                                onMouseEnter={() => handleMessageBoxHover(index, message.payload)}
                                onMouseLeave={() => handleMessageBoxLeave(index)}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                    }}
                                >
                                    <Box>
                                        {/* <Typography>{message.payload.main}, {message.payload.sub} detected in zone {message.payload.zone}</Typography> */}
                                        <Typography>{message.payload.main}, {message.payload.sub} detected</Typography>
                                        <Typography>Object ID: {message.payload.object_id}</Typography>

                                        {message.payload.object_id === "111" || message.payload.object_id === "222" || message.payload.object_id === "333" ? (
                                            <Typography>{convertUnixTimestamp(message.payload.timestamp)}</Typography>
                                        ) : (
                                            <Typography>{message.payload.timestamp}</Typography>
                                        )}

                                    </Box>
                                    <Box sx={{ width: "3vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <WarningRoundedIcon
                                                sx={{
                                                    color: message.payload.status === "opened" ? "#ff3d00"
                                                        : message.payload.status === "update" ? "#ff9100"
                                                            : message.payload.status === "keep_alive" ? "#ffc400"
                                                                : "#4caf50"
                                                }}
                                            />
                                            <Typography sx={{ fontSize: "12px" }}>{message.payload.status}</Typography>
                                        </Box>
                                        {messageHoverStates[index] &&
                                            // <Box sx={{ marginLeft: "15px", marginRight: "10px" }}>
                                            <Tooltip title="Delete message">
                                                <IconButton
                                                    className="clearMessage"
                                                    variant="contained"
                                                    sx={{ marginLeft: "10px", marginRight: "25px", color: "#3F3E3B" }}
                                                    onClick={() => handleDeleteButtonClick(message)}
                                                >
                                                    <DeleteIcon
                                                        // sx={{ color: "#3F3E3B" }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                            // </Box>
                                        }
                                    </Box>
                                </Box>
                                {messageHoverStates[index] && message.payload.status !== "closed" &&
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                        <Button
                                            sx={{ textTransform: "lowercase" }}
                                            onClick={() => handleCloseEvent(message)}
                                        >
                                            Close event
                                        </Button>
                                    </Box>
                                }
                                <hr />
                            </Box>
                        ))}
                        {role === "admin" && (
                            <Box sx={{ display: "flex", justifyContent: "center" }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: "150px",
                                        borderRadius: "30px",
                                        position: "absolute",
                                        bottom: "20px",
                                    }}
                                    color="error"
                                    onClick={handleDisconnectButton}
                                >
                                    Disconnect
                                </Button>
                            </Box>
                        )}
                    </Box>
                    <Map />
                </Box>
            </div>
        </MapProvider >
    )
}

export default AlertsPage;